import React from 'react';
import './Footer.css'; // You can style it using a CSS file

function Footer() {
  return (
    <footer className="footer">
       <p>© 2025 Frontier Health | Contact: +44 207 081 9974 | <a href="/privacy-notice" className="footer-link">Privacy Notice</a> | <a href="/carbon-plan" className="footer-link">Carbon Reduction Plan</a> | <a href="/modern-slavery-statement" className="footer-link">Modern Slavery Statement</a> | <a href="/terms-of-service" className="footer-link">Terms Of Service</a> </p>
    </footer>

  );
}

export default Footer;
